<template>
  <div class="patient page">
    <NavMenu defaultActive="/patient/list">
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/edit.png" alt="ico-title" />
              <span>编辑病人</span>
            </div>
            <div class="mark">
              <span class="sig">*</span>
              <span>为必填项</span>
            </div>
          </h2>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="form"
          >
            <div class="form-left">
              <el-form-item label="病人编号:" prop="patient_number"
                ><el-input v-model="ruleForm.patient_number"></el-input
              ></el-form-item>
              <el-form-item label="病人姓名:" prop="patient_name"
                ><el-input v-model="ruleForm.patient_name"></el-input
              ></el-form-item>
              <el-form-item label="出生日期:" prop="birthday">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="ruleForm.birthday"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="性别:" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio class="radio" label="0">男</el-radio>
                  <el-radio class="radio" label="1">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-right">
              <el-form-item label="备注:">
                <el-input
                  :autosize="{ minRows: 6 }"
                  type="textarea"
                  maxlength="100"
                  show-word-limit
                  v-model="ruleForm.note"
                ></el-input>
              </el-form-item>
            </div>
            <div class="foot">
              <input
                type="button"
                value="保存"
                class="btn-submit"
                @click="submitForm('ruleForm')"
              />
              <input
                type="button"
                value="重置"
                class="btn-cancel"
                @click="resetForm('ruleForm')"
              />
            </div>
          </el-form>
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        patient_number: "",
        patient_name: "",
        birthday: "",
        sex: "",
        note: "",
      },
      rules: {
        patient_name: [
          { required: true, message: "请输入病人姓名", trigger: "blur" },
        ],
        patient_number: [
          { required: true, message: "请输入病人编号", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
    };
  },
  methods: {
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //表单验证通过
          console.log('succes submit')
        } else {
          console.log("error submit");
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.right-content {
  min-height: 757px;
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}
.right-content .form {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.right-content .form-left,
.right-content .form-right {
  width: 45%;
}
.right-content .form .foot {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
}
.right-content .form .foot input {
  margin: 0 12px;
}
</style>
